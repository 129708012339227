import { Tabs } from "antd";
import ManageFaults from "./ManageFaults";
import UpdateProduct from "./UpdateProduct";
const { TabPane } = Tabs;

export default function AddProduct({ product, refresh }) {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Update product" key="1">
        <UpdateProduct product={product} refresh={refresh} />
      </TabPane>
      <TabPane tab="Manage common faults" key="2">
        <ManageFaults product={product} refresh={refresh} />
      </TabPane>
    </Tabs>
  );
}

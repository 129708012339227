import { Image, Popover } from "antd";

export default function Message({ message, isSelf, name }) {
  return (
    <div
      style={{
        borderWidth: 2,
        borderRadius: 10,
        borderColor: "#1890ff",
        margin: 2,
        cursor: "pointer",
        float: isSelf ? "right" : "left",
      }}
    >
      {message.attachmentURL ? (
        <Image src={message.attachmentURL} height={150} />
      ) : (
        <Popover
          placement={isSelf ? "left" : "right"}
          content={
            name + " - " + new Date(message.date_time).toLocaleString("en-GB")
          }
          trigger="click"
        >
          <div
            style={{
              borderStyle: "solid",
              borderRadius: 20,
              borderWidth: 1,
              borderColor: "#40a9ff",
              backgroundColor: isSelf ? "#40a9ff" : "",
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10,
              maxWidth: 300,
              float: isSelf ? "right" : "left",
            }}
          >
            {message.content}
          </div>
        </Popover>
      )}
    </div>
  );
}

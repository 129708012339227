import { Card, Image } from "antd";
import { PlusCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import blank from "../images/blank.png";
import { useEffect, useState } from "react";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

export default function ProductFault({ fault, onClick }) {
  //Returns all the tickets of the selected customer, showing the ticket
  //priority, customer name, ticket title and the date the ticket was created.
  const [image, setImage] = useState("");
  useEffect(() => {
    setImage("");
    if (fault.image != "") {
      const storage = getStorage();
      getDownloadURL(ref(storage, fault.image)).then(setImage);
    }
  }, [fault]);
  return (
    <Card
      onClick={() => onClick(fault.id)}
      title={
        <>
          {/* display either a plus icon or a tick based on selected */}
          {fault.selected ? (
            <CheckCircleOutlined />
          ) : (
            <PlusCircleOutlined />
          )}{" "}
          {`${fault.name} - priority ${fault.priority}`}
        </>
      }
      cover={
        <Image
          width={300}
          height={300}
          style={{ overflow: "hidden", padding: 2, objectFit: "cover" }}
          src={image}
          fallback={blank}
          preview={false}
        />
      }
      size="small"
      bordered={true}
      style={{
        width: "100%",
        borderWidth: 2,
        // ajust the colour of the border based on selected
        borderColor: fault.selected ? "lightgreen" : "white",
        margin: 2,
        cursor: "pointer",
      }}
    >
      {/* To extract images from the ticket */}
      <p>{fault.description}</p>
      {fault.emergency_text && <p>emergency text: {fault.emergency_text}</p>}
      {fault.self_help && <p>link to self help: {fault.self_help}</p>}
    </Card>
  );
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../index.css";
import { Col, Row } from "antd";
import AddProduct from "../components/AddProduct";
import Product from "../components/Product";
import ManageProduct from "../components/ManageProduct";
import getDocument from "../scripts/getDocument";
import NavBar from "../components/NavBar";

export default function ManageProducts() {
  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState(null);

  const updateProducts = () => {
    setSelected(null);
    setProducts([]);
    getDocument("/Products")
      .then(setProducts)
      .catch((err) =>
        console.error(products, "unable to retrieve products -", err)
      );
  };

  useEffect(updateProducts, []);

  //only allow one product to be selected at a time, clicking a selected product
  //unselects it
  const markAsSelected = (id) => {
    const modified = products;
    const newIndex = products.findIndex((product) => id == product.id);

    modified[newIndex].selected = !modified[newIndex].selected;

    if (selected == null) {
      setSelected(id);
    } else if (selected != id) {
      const oldIndex = products.findIndex((product) => product.id == selected);
      modified[oldIndex].selected = !modified[oldIndex].selected;
      setSelected(id);
    } else {
      setSelected(null);
    }

    setProducts([...modified]);
  };

  const navigate = useNavigate();

  return (
    <div>
      <NavBar />
      <div style={{ margin: 10 }}>
        <Row>
          <Col span={6}>
            {products.map((product) => (
              <Product
                key={product.id}
                product={product}
                onClick={markAsSelected}
              />
            ))}
          </Col>
          <Col span={18}>
            <div className="form" style={{ marginLeft: 5 }}>
              {selected == null ? (
                <AddProduct refresh={updateProducts} />
              ) : (
                <ManageProduct
                  product={
                    products.filter((product) => product.id == selected)[0]
                  }
                  refresh={updateProducts}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

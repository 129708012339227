import { message } from "antd";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";
import uploadImage from "./uploadImage";

//Given the product data + image, add the product to the database
export default async function addProduct(values, image) {
  uploadImage("Products", image)
    .then((data) => {
      values.image = data;
      values.stock = parseInt(values.stock);
      values.price = parseInt(values.price);

      addDoc(collection(db, "Products"), values)
        .then(() => message.success("Product added"))
        .catch(() => {
          message.error("Failed uploading data");
        });
    })
    .catch(() => {
      message.error("Invalid data");
    });
}

import { useState, useEffect } from "react";
import getDocument from "../scripts/getDocument";
import NavBar from "../components/NavBar";
import { Button, Card, message } from "antd";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

export default function AddPendingStaff() {
  const [pendingStaff, setPendingStaff] = useState([]);

  // On the add button being pressed, add the staff member
  // to the staff and remove them from pending staff
  const add = (staff) => {
    setDoc(doc(db, "Staff", staff.id), {
      email: staff.email,
      first_name: staff.first_name,
      last_name: staff.last_name,
      role: staff.role,
    }).then(() => {
      message.success(`Added ${staff.first_name} ${staff.last_name} to staff`);
      remove(staff);
    });
  };

  //removes a pending staff member from the pending staff
  const remove = (staff) => {
    deleteDoc(doc(db, "StaffPending", staff.id))
      .then(() =>
        message.success(
          `Removed ${staff.first_name} ${staff.last_name} from pending staff`
        )
      )
      .catch((err) => console.error(err));
    getDocument("StaffPending").then(setPendingStaff);
  };

  // on page load get all pending staff
  useEffect(() => {
    getDocument("StaffPending").then(setPendingStaff);
  }, []);

  return (
    <div>
      <NavBar />
      <h1>Add pending staff</h1>
      {pendingStaff &&
        pendingStaff.map(
          (staff) =>
            staff.email && (
              <Card key={staff.id}>
                <p>{staff.first_name + " " + staff.last_name}</p>
                <p>{staff.email}</p>
                <Button onClick={() => add(staff)}>Add To Staff</Button>
                <Button onClick={() => remove(staff)}>
                  Remove Staff Member
                </Button>
              </Card>
            )
        )}
    </div>
  );
}

import { Button, Input, Form, AutoComplete, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import addProduct from "../scripts/addProduct";

export default function AddProduct({ refresh }) {
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState([]);
  const [image, setImage] = useState("");

  //collect all product ID's from the database
  const fetchProductTypes = async () => {
    try {
      const productTypesRef = query(collection(db, "/ProductTypes"));
      const productTypes = await getDocs(productTypesRef);
      productTypes.forEach((item) =>
        setProducts((oldItems) => [...oldItems, item.id])
      );
    } catch (error) {
      console.log("failed to get product types", error);
    }
  };

  //ajust the dropdown by searching product types
  const handleProductSearch = (value) => {
    var reg = new RegExp(value);
    const result = products.filter((term) => reg.test(term)).slice(0, 5);
    setSearch(result);
  };

  //on form submit add the form contents to the database
  const onFinish = (values) => {
    addProduct(values, image).then(refresh);
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Error uploading the data");
  };

  //collect the products when the page is reloaded
  useEffect(() => {
    setProducts([]);
    fetchProductTypes();
  }, []);
  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <h2>Add Product</h2>
      <Form.Item label="Product name" name="name">
        <Input placeholder="Product name" />
      </Form.Item>
      <Form.Item label="Price" name="price">
        <Input prefix="£" placeholder="Price" />
      </Form.Item>
      <Form.Item label="Stock" name="stock">
        <Input placeholder="Stock" />
      </Form.Item>
      <Form.Item label="Product type" name="product_type">
        <AutoComplete
          dataSource={search}
          onSearch={handleProductSearch}
          placeholder="Product type"
        />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <TextArea
          placeholder="description"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>
      <Form.Item label="Upload image" name="image">
        <input
          // allows you to reach into your file directory and upload image to the browser
          type="file"
          onChange={(e) => {
            setImage(e.target.files[0]);
          }}
        />
      </Form.Item>

      <Form.Item>
        <Form.Item>
          <Button className="button" type="primary" htmlType="submit">
            Add product
          </Button>
        </Form.Item>
      </Form.Item>
    </Form>
  );
}

import { Button, Form, Input } from "antd";
import { db } from "../firebase";
import "../index.css";
import { doc, setDoc } from "firebase/firestore";
import NavBar from "../components/NavBar";

export default function AddProductType() {
  //on form submit add the form contents to the database
  const onFinish = (values) => {
    setDoc(doc(db, "ProductTypes", values.name), {
      name: values.name,
      number_of_products: parseInt(values.stock),
    })
      .then(function () {
        console.log("Value successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing Value: ", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <NavBar />
      <div style={{ width: "100%", textAlign: "center" }}>
        <div className="form" style={{ width: "33%", display: "inline-block" }}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <h2>Add Product Type</h2>
            <Form.Item label="Product type name" name="name">
              <Input placeholder="Product type name" />
            </Form.Item>
            <Form.Item label="Stock" name="stock">
              <Input placeholder="Number of products" />
            </Form.Item>

            <Form.Item>
              <Form.Item>
                <Button className="button" type="primary" htmlType="submit">
                  Add product
                </Button>
              </Form.Item>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from "react";
import { Button, Calendar, Popover, message, Space } from "antd";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase";
import getDocument from "../scripts/getDocument";

export default function Calander({ user }) {
  const [installers, setInstallers] = useState([]);
  const [appointments, setAppointments] = useState([]);

  //adds all installers to installers state
  useEffect(() => {
    setInstallers([]);
    getDocument(`Users/${user.id}/MaintenanceAppointments`).then(
      setAppointments
    );

    const q = query(collection(db, `Installers/`));
    getDocs(q).then((snap) => {
      snap.forEach((item) => {
        const data = item.data();
        data.id = item.id;
        setInstallers((oldItems) => [...oldItems, data]);
      });
    });
  }, []);

  // returns an availability object for each installer on a given day
  const getAvalabilities = (dayCode) => {
    var availabilities = [];
    installers.forEach((installer) => {
      if (installer) {
        const times = installer[dayCode];
        if (times && times.length != 0) {
          availabilities.push({
            id: installer.id,
            name: installer.first_name + " " + installer.last_name,
            str: times[0] + " - " + times[1],
          });
        }
      }
    });
    return availabilities;
  };

  // returns an appointment object for each appontment on a given day
  const getAppointments = (unixTime) => {
    var daysAappointments = [];
    if (appointments == []) return;

    appointments.forEach((appointment) => {
      const start = new Date(
        new Date(appointment.start_time.seconds * 1000).setHours(0, 0, 0, 0)
      ).getTime();
      const timeToCheck = new Date(
        new Date(unixTime * 1000).setHours(0, 0, 0, 0)
      ).getTime();

      // if the appointment is today, add it to the array and attack the times
      if (start == timeToCheck) {
        appointment.start = new Date(appointment.start_time.seconds * 1000)
          .toLocaleTimeString()
          .replace(/(.*)\D\d+/, "$1");
        appointment.end = new Date(appointment.end_time.seconds * 1000)
          .toLocaleTimeString()
          .replace(/(.*)\D\d+/, "$1");
        daysAappointments.push(appointment);
      }
    });

    return daysAappointments;
  };

  // returns a button that allows a staff member to see that an appontment exists at this time
  const showAppointments = (unixTime) => {
    const daysAappointments = getAppointments(unixTime);

    var installerKey = {};
    installers.forEach((installer) => {
      installerKey[installer.id] =
        installer.first_name + " " + installer.last_name;
    });

    return daysAappointments.map((appointment) => (
      <Popover
        key={appointment.start_time}
        placement="bottom"
        content={
          <Space direction="vertical">
            <h3>{appointment.location}</h3>
            <p>Installer: {installerKey[appointment.assigned_installer[0]]}</p>
            <p>Time: {appointment.start + " - " + appointment.end}</p>
          </Space>
        }
        trigger="click"
      >
        <Button>{appointment.start + " - " + appointment.end}</Button>
      </Popover>
    ));
  };

  // allows a staff member to see a list of installers available on a given day
  const availableOnDay = (dayCode, unixTime) => {
    const availabilities = getAvalabilities(dayCode);

    return (
      <Popover
        key={unixTime}
        placement="bottom"
        content={
          <Space direction="vertical">
            <h3>Click to copy shift code</h3>
            {availabilities.map((availability) => (
              <Button
                block
                key={availability.id}
                onClick={() => {
                  navigator.clipboard.writeText(
                    availability.id + " " + unixTime
                  );
                  message.success("Appointment code copied");
                }}
              >
                {availability.str + " - " + availability.name}
              </Button>
            ))}
          </Space>
        }
        trigger="click"
      >
        <Button type="link">Add</Button>
      </Popover>
    );
  };

  //given a day, display the avlabilities of installers and any appontments
  const getAvalability = (data) => {
    const days = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    const dayCode = `${days[data.day()]}_availability`;
    const unixTime = data.unix();
    return (
      <Space direction="vertical">
        {availableOnDay(dayCode, unixTime)}
        {showAppointments(unixTime)}
      </Space>
    );
  };

  return <Calendar dateCellRender={getAvalability} />;
}

import { useEffect, useState } from "react";
import { Image, Tooltip } from "antd";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import kitchen from "../images/kitchen.jpg";

export default function ChatBubble({ chat, onSelect }) {
  const [image, setImage] = useState("");
  const storage = getStorage();
  useEffect(() => {
    if (chat.chatIcon && chat.chatIcon != "") {
      getDownloadURL(ref(storage, chat.chatIcon)).then(setImage);
    }
  }, []);
  return (
    <Tooltip title={chat.title} placement="bottom">
      <Image
        src={image}
        fallback={kitchen}
        preview={false}
        width={40}
        height={40}
        style={{ borderRadius: 50, objectFit: "cover", cursor: "pointer" }}
        onClick={() => onSelect(chat)}
      />
    </Tooltip>
  );
}

import { Input, Form, Modal, TimePicker } from "antd";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  updateDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React from "react";
import { db, functions } from "../firebase";

export default function CreateAppointment({
  tickets,
  installers,
  customer,
  close,
}) {
  const selected = tickets.filter(({ selected }) => selected).length;
  const [form] = Form.useForm();

  //Adds an appointment to the users appointments collection
  //then for each ticket, assigns it this appointment
  const onFinish = ({ code, start_time, end_time }) => {
    close();
    if (selected == 0) {
      alert("no selected appointments");
      return;
    }

    //get installer id and day of installation from code from calander
    const [installerId, day] = code.split(" ");

    //find time of midnight of installation day
    const targetDay = new Date(day * 1000);
    targetDay.setHours(0, 0, 0, 0);
    const targetMidnight = targetDay.getTime() / 1000;

    //get time of midnight today
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const midnight = now.getTime() / 1000;

    //figure out the time of the instalation
    start_time = parseInt(targetMidnight) + (start_time.unix() - midnight);
    end_time = parseInt(targetMidnight) + (end_time.unix() - midnight);

    const assignees = installers.filter(
      (installer) => installer.id == installerId
    );

    if (assignees.length == 0) {
      alert("not a vailid installer");
      return;
    }

    addDoc(collection(db, `Users/${customer.id}/MaintenanceAppointments`), {
      location: customer.address,
      start_time: new Date(start_time * 1000),
      end_time: new Date(end_time * 1000),
      assigned_installer: assignees.map(({ id }) => id),
      is_cancelled: false,
    })
      .then(function (value) {
        tickets
          .filter(({ selected }) => selected)
          .forEach((ticket) => {
            const ticketRef = doc(
              db,
              `Users/${customer.id}/MaintenanceTickets/${ticket.id}`
            );
            updateDoc(ticketRef, {
              related_appointments: arrayUnion(value.id),
              assigned_installer: arrayUnion(installerId),
            })
              .then(() => {
                const changeStatus = httpsCallable(
                  functions,
                  "tickets-changeStatus"
                );
                if (ticket.review_status == 1) {
                  changeStatus({
                    ticketID: ticket.id,
                    userID: customer.id,
                    status: 2,
                  });
                }
              })
              .catch(function (error) {
                console.error("Error adding appointment to ticket: ", error);
              });
          });
      })
      .catch(function (error) {
        console.error("Error creating appointment: ", error);
      });
  };

  return (
    <Modal
      title={`Create appointment - ${selected} ticket${
        selected == 1 ? "" : "s"
      } Selected`}
      visible={true}
      okText={"create"}
      cancelText="Cancel"
      onCancel={close}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="Appointment Code"
          name="code"
          rules={[
            { required: true, message: "Please input an Appointment Code" },
          ]}
        >
          <Input placeholder="Appointment Code" />
        </Form.Item>
        <Form.Item
          label="Start Time"
          name="start_time"
          rules={[{ required: true, message: "Please input a Start Time" }]}
        >
          <TimePicker />
        </Form.Item>
        <Form.Item
          label="End Time"
          name="end_time"
          rules={[{ required: true, message: "Please input a End Time" }]}
        >
          <TimePicker />
        </Form.Item>
      </Form>
    </Modal>
  );
}

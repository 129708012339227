import { useState } from "react";
import { Button, Form, Input } from "antd";
import { doc, setDoc } from "firebase/firestore";
import NavBar from "../components/NavBar";
import { db } from "../firebase";

export default function AddOrganisation() {
  const [orgName, setOrgName] = useState("");
  const [form] = Form.useForm();

  //code taken from https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case, 29/03/2022
  const removeSpaces = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return "";
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  };

  //on form submit add the form contents to the database
  const onFinish = (values) => {
    setDoc(doc(db, "Companies", removeSpaces(values.company_name)), values)
      .then(function () {
        form.resetFields();
      })
      .catch(function (error) {
        console.error("Error writing Value: ", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <NavBar />
      <div style={{ width: "100%", textAlign: "center" }}>
        <div className="form" style={{ width: "33%", display: "inline-block" }}>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <h2>Add Organisation</h2>
            <Form.Item label="Organisation Name" name="company_name">
              <Input
                placeholder="Organisation Name"
                onChange={(change) => setOrgName(change.target.value)}
              />
            </Form.Item>
            <Form.Item label="Adress" name="address">
              <Input placeholder="Adress" />
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item label="Phone number" name="phone_number">
              <Input placeholder="Phone Number" />
            </Form.Item>
            <Form.Item label="Website URL" name="website">
              <Input placeholder="Website URL" />
            </Form.Item>

            <Form.Item>
              <Form.Item>
                <Button className="button" type="primary" htmlType="submit">
                  Add Organisation
                </Button>
              </Form.Item>
            </Form.Item>
          </Form>
          <h3>{"Organisation ID: " + removeSpaces(orgName)}</h3>
        </div>
      </div>
    </div>
  );
}

import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase";

//gets all documents from the given database address
export default async function getDocument(address) {
  const docRef = query(collection(db, address));
  const docData = (await getDocs(docRef)).docs;

  var items = [];
  //for each itme, add it to the data array along with its ID
  docData.forEach((item) => {
    var data = item.data();
    data.id = item.id;
    items.push(data);
  });
  return items;
}

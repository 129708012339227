import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { sendPasswordReset } from "../firebase";
import { Form, Input, Button } from "antd";

import "../index.css";
import kitchen from "../images/kitchen.jpg";

function Reset() {
  const navigate = useNavigate();

  const onFinish = (values) => {
    sendPasswordReset(values.email);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="screen" style={{ backgroundImage: `url(${kitchen})` }}>
      <div className="form">
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <h2>Reset Password</h2>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Form.Item>
              <Button className="button" type="primary" htmlType="submit">
                Send password reset email
              </Button>
            </Form.Item>

            <div>
              Don't have an account? <Link to="/register">Register</Link> now.
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
export default Reset;

import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { Button, Menu, Space } from "antd";
import CustomerFaults from "../components/CustomerFaults";
import Calander from "../components/Calander";

import {
  CalendarOutlined,
  AlertOutlined,
  MessageOutlined,
  UserOutlined,
} from "@ant-design/icons";
import LiveChat from "../components/LiveChat";
import NavBar from "../components/NavBar";

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [userData, setUserData] = useState([]);
  const [dashScreen, setDashScreen] = useState(null);
  const navigate = useNavigate();

  const fetchUserName = async () => {
    try {
      const usersRef = collection(db, "/Users");
      const users = await getDocs(usersRef);
      const userData = users.docs.map((user) => ({
        id: user.id,
        ...user.data(),
      }));
      setUserData(userData);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserName();
  }, [user, loading]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <NavBar />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div
          style={{
            height: "100%",
            borderRight: 0,
            maxWidth: "25vw",
            minWidth: "25vw",
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
          >
            {userData.map((user) => (
              <Menu.Item
                icon={<UserOutlined />}
                key={user.id}
                onClick={() =>
                  setDashScreen({ user: user, screen: "appointment" })
                }
              >
                {user.first_name + " " + user.last_name}
              </Menu.Item>
            ))}
          </Menu>
        </div>
        {dashScreen && (
          <>
            <Space direction="vertical" style={{ padding: 5 }}>
              <Button
                type="primary"
                shape="circle"
                icon={<CalendarOutlined />}
                onClick={() =>
                  setDashScreen({ ...dashScreen, screen: "calander" })
                }
              />
              <Button
                type="primary"
                shape="circle"
                icon={<AlertOutlined />}
                onClick={() =>
                  setDashScreen({ ...dashScreen, screen: "appointment" })
                }
              />
              <Button
                type="primary"
                shape="circle"
                icon={<MessageOutlined />}
                onClick={() =>
                  setDashScreen({ ...dashScreen, screen: "liveChat" })
                }
              />
            </Space>

            {dashScreen && dashScreen.screen == "appointment" && (
              <CustomerFaults customer={dashScreen.user} />
            )}
            {dashScreen && dashScreen.screen == "calander" && (
              <Calander user={dashScreen.user} />
            )}
            {dashScreen && dashScreen.screen == "liveChat" && (
              <LiveChat user={dashScreen.user} />
            )}
          </>
        )}
      </div>
    </Space>
  );
}
export default Dashboard;

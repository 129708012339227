import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Space } from "antd";
import getTickets from "../scripts/getTickets";
import Faults from "./Faults";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import CreateAppointment from "./CreateAppointment";

export default function CustomerFaults({ customer }) {
  const [tickets, setTickets] = useState([]);
  const [installers, setInstallers] = useState([]);
  const [formVisible, setFormVisible] = useState(false);

  //collects customer tickets and adds them to the state
  useEffect(async () => {
    const ticketPromise = await getTickets(customer);
    ticketPromise.map((ticket) => (ticket.selected = false));
    setTickets(ticketPromise);
    setInstallers([]);
    fetchInstallers();
  }, [customer]);

  const fetchInstallers = async () => {
    try {
      const installerRef = query(collection(db, "/Installers"));
      const installersData = await getDocs(installerRef);
      installersData.forEach((item) => {
        const installer = item.data();
        installer.id = item.id;
        setInstallers((oldItems) => [...oldItems, installer]);
      });
    } catch (error) {
      console.log("failed to get product types", error);
    }
  };

  const markAsSelected = (id) => {
    const modifiedTickets = tickets;
    const objIndex = tickets.findIndex((ticket) => ticket.id == id);
    modifiedTickets[objIndex].selected = !modifiedTickets[objIndex].selected;
    setTickets([...modifiedTickets]);
  };

  return (
    <div style={{ margin: 10, height: "100%" }}>
      {/* list all faults that the user has */}
      <div>
        <Button
          type="primary"
          onClick={() => setFormVisible(true)}
          title="button"
        >
          Create appointment
        </Button>
      </div>
      {tickets.map((ticket) => (
        <Space direction="vertical">
          <Faults
            key={ticket.id}
            ticket={ticket}
            customer={customer}
            onClick={markAsSelected}
          />
        </Space>
      ))}
      {formVisible && (
        <CreateAppointment
          close={() => setFormVisible(false)}
          tickets={tickets}
          installers={installers}
          customer={customer}
        />
      )}
    </div>
  );
}

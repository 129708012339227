import { Button, Input, Form, AutoComplete } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { collection, doc, getDocs, query, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import uploadImage from "../scripts/uploadImage";

export default function UpdateProduct({ product, refresh }) {
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState([]);
  const [image, setImage] = useState("");

  //collect all product ID's from the database
  const fetchProductTypes = async () => {
    try {
      const productTypesRef = query(collection(db, "/ProductTypes"));
      const productTypes = await getDocs(productTypesRef);
      productTypes.forEach((item) =>
        setProducts((oldItems) => [...oldItems, item.id])
      );
    } catch (error) {
      console.error("failed to get product types", error);
    }
  };

  //ajust the dropdown by searching product types
  const handleProductSearch = (value) => {
    var reg = new RegExp(value);
    const result = products.filter((term) => reg.test(term)).slice(0, 5);
    setSearch(result);
  };

  //on form submit add the form contents to the database
  const onFinish = (values) => {
    uploadImage("Products", image)
      .then((data) => {
        values.image = data;
        Object.keys(values).forEach(
          (key) => (values[key] = !values[key] ? product[key] : values[key])
        );

        values.stock = parseInt(values.stock);
        values.price = parseInt(values.price);

        //only update the product fields that have not been left blank
        setDoc(doc(db, "Products", product.id), values)
          .then(refresh)
          .catch(function (error) {
            console.error("Error writing Value: ", error);
          });
      })
      .catch(function (error) {
        console.error("Error uploading image: ", error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  //collect the products when the page is reloaded
  useEffect(() => {
    setProducts([]);
    fetchProductTypes();
  }, []);

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item label="Product name" name="name">
        <Input placeholder={product.name} />
      </Form.Item>
      <Form.Item label="Price" name="price">
        <Input prefix="£" placeholder={product.price} />
      </Form.Item>
      <Form.Item label="Stock" name="stock">
        <Input placeholder={product.stock} />
      </Form.Item>
      <Form.Item label="Product type" name="product_type">
        <AutoComplete
          dataSource={search}
          onSearch={handleProductSearch}
          placeholder={product.product_type}
        />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <TextArea
          placeholder={product.description}
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>
      <Form.Item label="Upload different image" name="image">
        <input
          // allows you to reach into your file directory and upload image to the browser
          type="file"
          onChange={(e) => {
            setImage(e.target.files[0]);
          }}
        />
      </Form.Item>

      <Form.Item>
        <Form.Item>
          <Button className="button" type="primary" htmlType="submit">
            Update product
          </Button>
        </Form.Item>
      </Form.Item>
    </Form>
  );
}

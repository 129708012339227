export default {
  apiKey: "AIzaSyC0VIygmPN38AJuYLmPFB4mqlN_roVx7VQ",
  authDomain: "ikea-project-uon.firebaseapp.com",
  databaseURL:
    "https://ikea-project-uon-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ikea-project-uon",
  storageBucket: "ikea-project-uon.appspot.com",
  messagingSenderId: "499161187179",
  appId: "1:499161187179:web:8ac41dc265e9d873da422e",
  measurementId: "G-FHFF482F6W",
};

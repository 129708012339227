export default function getReviewStatus(reviewStatus) {
  switch (reviewStatus) {
    case 0:
      reviewStatus = "Awaiting review";
      break;
    case 1:
      reviewStatus = "Being reviewed";
      break;
    case 2:
      reviewStatus = "Appointment made";
      break;
    case 3:
      reviewStatus = "Under repair";
      break;
    case 4:
      reviewStatus = "Work being Reviewed";
      break;
    case 5:
      reviewStatus = "Completed Ticket";
  }
  return reviewStatus;
}

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Screens/Login";
import Register from "./Screens/Register";
import Reset from "./Screens/Reset";
import Dashboard from "./Screens/Dashboard";
import ManageProducts from "./Screens/ManageProducts";
import AddProductType from "./Screens/AddProductType";
import AddOrganisation from "./Screens/AddOrganisation";
import AddPendingStaff from "./Screens/AddPendingStaff";
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/reset" element={<Reset />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/manageProducts" element={<ManageProducts />} />
        <Route exact path="/addProductType" element={<AddProductType />} />
        <Route exact path="/addOrganisation" element={<AddOrganisation />} />
        <Route exact path="/AddPendingStaff" element={<AddPendingStaff />} />
      </Routes>
    </Router>
  );
}
export default App;

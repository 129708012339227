import { Badge } from "antd";

export default function Urgency({ value }) {
  const getColor = (x) => {
    switch (x) {
      case 0:
        return "turquoise";
      case 1:
        return "blue";
      case 2:
        return "green";
      case 3:
        return "gold";
      case 4:
        return "orange";
      case 5:
        return "red";
      default:
        return "turquoise";
    }
  };

  return (
    <Badge
      count={value}
      style={{
        backgroundColor: getColor(value),
      }}
    />
  );
}

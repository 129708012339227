import { auth } from "../firebase";
import { getDatabase, onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { Button, Space } from "antd";
import Message from "./Message";
import SendMessage from "./SendMessage";

export default function Chat({ chat }) {
  const [messages, setMessages] = useState([]);
  const [chatHeight, setChatHeight] = useState(300);
  const [members, setMembers] = useState([]);

  //given a chat collect all messages and save them in a state
  const db = getDatabase();

  useEffect(() => {
    const membersRef = ref(db, `members/${chat.id}`);
    onValue(membersRef, (snapshot) => {
      //If the staff member is the auther of the message, set isMe to true
      setMembers(snapshot.val());
    });

    const messageRef = ref(db, `messages/${chat.id}`);
    onValue(messageRef, (snapshot) => {
      setMessages([]);
      const sorted = Object.values(snapshot.val()).sort((a, b) => {
        return a.date_time - b.date_time;
      });

      //If the staff member is the auther of the message, set isMe to true
      sorted.forEach((message) => {
        message.isMe = auth.lastNotifiedUid == message.author_id;
        setMessages((oldItems) => [...oldItems, message]);
      });
    });
  }, [chat]);

  const getListSize = () => {
    const newHeight = window.innerHeight;
    setChatHeight(newHeight);
  };

  useEffect(() => {
    setChatHeight(window.innerHeight);
    window.addEventListener("resize", getListSize);
  }, []);

  return (
    <div>
      <h1>{chat.title}</h1>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: chatHeight - 250,
        }}
      >
        <div
          style={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            {messages.map((message) => (
              <Message
                key={message.id}
                message={message}
                isSelf={message.author_id == auth.currentUser.uid}
                name={
                  members[message.author_id]
                    ? members[message.author_id]
                    : "A utomated"
                }
              />
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3>Members</h3>
          {members &&
            Object.values(members).map((member) => <Button>{member}</Button>)}
        </div>
      </div>
      <div style={{ width: "100%", padding: 10 }}>
        <SendMessage chat={chat} />
      </div>
    </div>
  );
}

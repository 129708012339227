import { Image } from "antd";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase";

import kitchen from "../images/kitchen.jpg";

export default async function getTickets(customer) {
  var tickets = [];

  try {
    //Find all the tickets that a customer has
    const ticketsRef = query(
      collection(db, `/Users/${customer.id}/MaintenanceTickets`)
    );

    const ticketData = await getDocs(ticketsRef);

    //for each ticket, add it to the data array if the current user has a ticket
    ticketData.docs.forEach((ticket) => {
      var data = ticket.data();
      data.id = ticket.id;
      data.images = [];
      //To show the images of each ticket of a given user
      data.files.forEach((image) => {
        data.images.push({
          id: image,
          element: (
            <Image
              key={image}
              height={100}
              style={{ overflow: "hidden", padding: 2 }}
              src={image}
              fallback={kitchen}
            />
          ),
        });
      });
      tickets.push(data);
    });

    // sort tickets by priority
    tickets = tickets.sort((a, b) => {
      return b.priority - a.priority;
    });

    return tickets;
  } catch (err) {
    console.error(err);
    alert("An error occured while fetching user data");
  }
}

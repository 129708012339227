import { Button, Popover, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { logout } from "../firebase";
import { AppstoreOutlined } from "@ant-design/icons";

export default function NavBar() {
  const navigate = useNavigate();
  return (
    <div style={{ backgroundColor: "lightblue", width: "100%" }}>
      <Popover
        content={
          <Space direction="vertical">
            <Button onClick={() => navigate("/dashboard")}>Dashboard</Button>
            <Button onClick={() => navigate("/manageProducts")}>
              Manage Products
            </Button>
            <Button onClick={() => navigate("/addProductType")}>
              Add Product Type
            </Button>
            <Button onClick={() => navigate("/addOrganisation")}>
              Add Organisation
            </Button>
            <Button onClick={() => navigate("/addPendingStaff")}>
              Add Pending Staff
            </Button>
            <Button onClick={logout}>Logout</Button>
          </Space>
        }
      >
        <AppstoreOutlined style={{ fontSize: 40, color: "#fff" }} />
      </Popover>
    </div>
  );
}

import { useState, useEffect } from "react";
import { child, get, getDatabase, query, ref } from "firebase/database";
import { auth, db } from "../firebase";
import { Space } from "antd";
import { collection, getDocs, where } from "firebase/firestore";
import ChatBubble from "./ChatBubble";
import Chat from "./Chat";

export default function LiveChat({ user }) {
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);

  useEffect(() => {
    //find all faults for this user that the staff member has access to
    setChats([]);
    const q = query(
      collection(db, `Users/${user.id}/MaintenanceTickets`),
      where("assigned_staff", "array-contains", auth.currentUser.uid)
    );

    const dbRef = ref(getDatabase());
    //collect the data along with the id of each of the faults
    getDocs(q)
      .then((qSnap) => {
        qSnap.docs.forEach((doc) => {
          //collects chat data from the realtime database and saves to state
          get(child(dbRef, `chats/${user.id}-${doc.id}`))
            .then((snapshot) => {
              if (snapshot.exists()) {
                const data = snapshot.val();
                data.id = `${user.id}-${doc.id}`;
                setChats((oldChats) => [...oldChats, data]);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        });
      })
      .then(() => {
        // adds the general chat to the chats state
        get(child(dbRef, `chats/${user.id}-general`)).then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            data.id = `${user.id}-general`;
            setChats((oldChats) => [...oldChats, data]);
          }
        });
      })
      .catch(console.error);
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: 40, flex: 1, padding: 10 }}>
        <Space>
          {chats.map((chat) => (
            <ChatBubble chat={chat} onSelect={setCurrentChat} />
          ))}
        </Space>
      </div>
      <div style={{ paddingLeft: 10, width: "100%" }}>
        {currentChat && <Chat chat={currentChat} />}
      </div>
    </div>
  );
}

import { useState, useEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { getDatabase, ref, onValue } from "firebase/database";
import { Button, Card, Space } from "antd";
import { PlusCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { auth, db, functions } from "../firebase";
import Urgency from "./Urgency";
import { doc, getDoc } from "firebase/firestore";
import getReviewStatus from "../scripts/getReviewStatus";

export default function Faults({ ticket, customer, onClick }) {
  const [isAssigned, setIsAssigned] = useState(false);
  const [staffName, setStaffName] = useState("");

  //Check if the staff member is assifned to this fault
  //if they are, update the IsAssigned state
  const ChechIfAssigned = () => {
    const db = getDatabase();
    const starCountRef = ref(
      db,
      `members/${customer.id}-${ticket.id}/${auth.currentUser.uid}`
    );
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data == staffName) {
        setIsAssigned(true);
      }
    });
  };

  //On loading, get the current member of staffs display name and
  // check what they are assigned to
  getDoc(doc(db, `Staff/${auth.currentUser.uid}`))
    .then((staff) => {
      setStaffName(staff.data().first_name + " " + staff.data().last_name);
    })
    .then(ChechIfAssigned);

  const closeFault = () => {
    const changeStatus = httpsCallable(functions, "tickets-changeStatus");
    changeStatus({
      ticketID: ticket.id,
      userID: customer.id,
      status: 5,
    });
    ticket.review_status = 5;
  };

  //assigns the staff member to the ticket using the firebase function
  const assignSelf = () => {
    setIsAssigned(null);
    const assignStaffMember = httpsCallable(
      functions,
      "tickets-assignStaffMember"
    );

    const changeStatus = httpsCallable(functions, "tickets-changeStatus");
    assignStaffMember({
      staffID: auth.currentUser.uid,
      staffName: staffName,
      ticketID: ticket.id,
      userID: customer.id,
    })
      .then(() => {
        if (ticket.review_status == 0) {
          changeStatus({
            ticketID: ticket.id,
            userID: customer.id,
            status: 1,
          });
          ticket.review_status = 1;
        }
        setIsAssigned(true);
      })
      .catch((error) => console.error(error));
  };

  //Returns all the tickets of the selected customer, showing the ticket
  //priority, customer name, ticket title and the date the ticket was created.
  return (
    <Card
      onClick={() => onClick(ticket.id)}
      title={
        <Space direction="horizontal">
          {/* display either a plus icon or a tick based on selected */}
          {ticket.selected ? <CheckCircleOutlined /> : <PlusCircleOutlined />}

          {ticket.title}
          {new Date(ticket.date_created.seconds * 1000).toLocaleDateString(
            "en-GB"
          )}
          <Button
            loading={isAssigned == null}
            disabled={isAssigned}
            onClick={() => assignSelf()}
          >
            {isAssigned ? "Being Reviewed" : "Join Review"}
          </Button>
          <Urgency value={ticket.priority} />
        </Space>
      }
      size="small"
      bordered={true}
      style={{
        width: 500,
        borderWidth: 2,
        borderRadius: 10,
        // ajust the colour of the border based on selected
        borderColor: ticket.selected ? "lightgreen" : "#1890ff",
        margin: 2,
        cursor: "pointer",
      }}
    >
      {/* To extract images from the ticket */}
      <p>
        <b>review status:</b> {getReviewStatus(ticket.review_status)}
      </p>
      {ticket.review_status !== 0 && ticket.review_status !== 5 && (
        <Button onClick={closeFault}>Close Fault</Button>
      )}
      <p>{ticket.description}</p>
      {ticket.images
        .filter((elem) => ticket.files.includes(elem.id))
        .map(({ element }) => element)}
    </Card>
  );
}

import { Button, Input, Space, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Modal from "antd/lib/modal/Modal";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import getDocument from "../scripts/getDocument";
import uploadImage from "../scripts/uploadImage";
import ProductFault from "./ProductFault";

export default function ManageFaults({ product, refresh }) {
  const [faults, setFaults] = useState([]);
  const [image, setImage] = useState("");
  const [selectedFault, setSelectedFault] = useState(null);
  const [form] = Form.useForm();
  useEffect(() => {
    getDocument(`/Products/${product.id}/Faults`).then(setFaults);
    console.log();
  }, [product]);

  const selected = (id) => {
    const fault = faults.find((fault) => fault.id == id);
    setSelectedFault(fault);
  };

  const addFault = (values) => {
    Object.keys(values).forEach(
      (key) => (values[key] = values[key] == undefined ? "" : values[key])
    );
    values.priority = parseInt(values.priority);

    uploadImage("FaultIcons", image)
      .then((data) => {
        values.image = data;

        addDoc(collection(db, `Products/${product.id}/Faults`), values)
          .then(refresh)
          .catch(function (error) {
            console.error("Error writing Value: ", error);
          });
      })
      .catch(function (error) {
        console.error("Error uploading image ", error);
      });
  };

  const updateFault = (values) => {
    //replaces untouched feilds with original values
    Object.keys(values).forEach(
      (key) =>
        (values[key] =
          values[key] == undefined ? selectedFault[key] : values[key])
    );
    values.priority = parseInt(values.priority);

    //adds storage Icon to database and updates the image url
    uploadImage("FaultIcons", image).then((data) => {
      values.image = data;

      //only update the product fields that have not been left blank
      setDoc(doc(db, `Products/${product.id}/Faults`, selectedFault.id), values)
        .then(refresh())
        .catch(function (error) {
          console.error("Error writing Value: ", error);
        });
    });
  };

  return (
    <>
      <div>
        <Button type="primary" onClick={() => setSelectedFault(false)}>
          Add Fault
        </Button>
      </div>
      {faults.map((fault) => (
        <Space direction="vertical">
          <ProductFault fault={fault} onClick={selected} />
        </Space>
      ))}
      <Modal
        title={selectedFault ? "Update Fault" : "Add Fault"}
        visible={selectedFault != null}
        okText={selectedFault ? "Update Fault" : "Add Fault"}
        cancelText="Cancel"
        onCancel={() => setSelectedFault(null)}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              selectedFault ? updateFault(values) : addFault(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form name="manageFault" initialValues={{ remember: true }} form={form}>
          <Form.Item label="Fault name" name="name">
            <Input
              placeholder={selectedFault ? selectedFault.name : "Fault name"}
            />
          </Form.Item>
          <Form.Item label="Priority" name="priority">
            <Input
              placeholder={selectedFault ? selectedFault.priority : "Priority"}
            />
          </Form.Item>
          <Form.Item label="Self help" name="self_help">
            <Input
              placeholder={
                selectedFault ? selectedFault.self_help : "Self help"
              }
            />
          </Form.Item>
          <Form.Item label="Emergency text" name="emergency_text">
            <TextArea
              placeholder={
                selectedFault ? selectedFault.emergency_text : "Emergency text"
              }
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea
              placeholder={
                selectedFault ? selectedFault.description : "description"
              }
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item label="Upload image" name="image">
            <input
              // allows you to reach into your file directory and upload image to the browser
              type="file"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

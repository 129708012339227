import { useEffect, useState } from "react";
import { Card, Image } from "antd";
import { PlusCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import blank from "../images/blank.png";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

export default function Product({ product, onClick }) {
  //collect the products image from the database and add it to the state
  const [image, setImage] = useState("");
  useEffect(() => {
    if (product.image != "") {
      const storage = getStorage();
      getDownloadURL(ref(storage, product.image)).then(setImage);
    }
  }, []);

  //Returns all the tickets of the selected customer, showing the ticket
  //priority, customer name, ticket title and the date the ticket was created.
  return (
    <Card
      onClick={() => onClick(product.id)}
      title={
        <>
          {/* display either a plus icon or a tick based on selected */}
          {product.selected ? (
            <CheckCircleOutlined />
          ) : (
            <PlusCircleOutlined />
          )}{" "}
          {`${product.name} - ${product.stock} in stock `}
        </>
      }
      size="small"
      bordered={true}
      style={{
        width: "100%",
        borderWidth: 2,
        // ajust the colour of the border based on selected
        borderColor: product.selected ? "lightgreen" : "white",
        marginBottom: 5,
        cursor: "pointer",
      }}
    >
      <p>{product.description}</p>
      <Image
        height={50}
        style={{ overflow: "hidden", padding: 2 }}
        src={image}
        fallback={blank}
      />
    </Card>
  );
}

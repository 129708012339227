import { Button, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { SendOutlined } from "@ant-design/icons";

export default function SendMessage({ chat }) {
  const [form] = Form.useForm();

  const sendMessage = (msg) => {
    form.resetFields();
    const sendTextMsg = httpsCallable(functions, "liveChat-sendTextMsg");
    sendTextMsg({
      chatID: chat.id,
      content: msg.text,
    }).catch((error) => console.error(error));
  };
  return (
    <div>
      <Form
        form={form}
        name="control-hooks"
        onFinish={(msg) => sendMessage(msg)}
        onFinishFailed={(e) => console.log(e)}
        layout="inline"
      >
        <Form.Item name="text" style={{ width: "50%" }}>
          <TextArea
            htmlType="submit"
            bordered={true}
            placeholder="Controlled autosize"
            autoSize={{ minRows: 2, maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item style={{ alignItems: "center" }}>
          <Button
            htmlType="submit"
            type="primary"
            shape="circle"
            icon={<SendOutlined />}
          />
        </Form.Item>
      </Form>
    </div>
  );
}

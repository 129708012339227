import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

// upload an image to the database and return the link to that image
// inside the database
export default async function uploadImage(location, image, url = false) {
  if (image == "") {
    return "";
  }
  const storage = getStorage();
  const storageRef = ref(storage, `/${location}/${image.name}`);
  uploadBytes(storageRef, image);

  return "gs://ikea-project-uon.appspot.com/" + storageRef.fullPath;
}
